import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import api from "../../../utils/agentApi";
import { set } from "date-fns";

function ViewAgent() {
  let { agentId } = useParams();

  const [agent, setAgent] = useState({});
  const [userPrompt, setUserPrompt] = useState("");
  const [runResult, setRunResult] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});
  const [websiteUrl, setWebsiteUrl] = useState("");

  useEffect(() => {
    const fetchAgent = async () => {
      setIsLoading(true);
      const response = await api.post(`/get_agent`, { agent_id: agentId });
      setAgent(response.data.data);
      setEditData(response.data.data); // Initialize edit data with agent data
      setIsLoading(false);
    };
    fetchAgent();
  }, [agentId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const toggleEdit = async () => {
    if (isEditing) {
      try {
        await api.post(`/new_or_update_agent`, { agent_id: agentId, ...editData });
        setAgent(editData);
      } catch (error) {
        console.error(error);
        return;
      }
    }
    setIsEditing(!isEditing);
  };


  async function RunAgent() {
    try {
      const agentData = {
        agent_id: agentId,
        user_prompt: userPrompt,
        website_url: websiteUrl,
      };
      const response = await api.post("/run_agent", agentData);
      const result = await response.data.result;
      setRunResult(result);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container>
      {isLoading && <p>Loading agent...</p>}
      {!isLoading && (
        <>
          <h1>{agent.name}</h1>
          <Row>
            <Col xs={2}>
              <strong>Name: </strong>
            </Col>
            <Col>
              {isEditing ? (
                <Form.Control
                  type="text"
                  name="name"
                  value={editData.name}
                  onChange={handleInputChange}
                />
              ) : (
                agent.name
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Description: </strong>
            </Col>
            <Col>
              {isEditing ? (
                <Form.Control
                  type="text"
                  name="description"
                  value={editData.description}
                  onChange={handleInputChange}
                />
              ) : (
                agent.description
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Created: </strong>
            </Col>
            <Col>{agent.created_at}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Type: </strong>
            </Col>
            <Col>{agent.custom_code === null || agent.custom_code === "" ? "AI" : "Calculation"}</Col>
          </Row>

          {(agent.custom_code === null || agent.custom_code === "") && (
            <>
              <Row>
                <Col xs={2}>
                  <strong>Model: </strong>
                </Col>
                <Col>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      name="model"
                      value={editData.model}
                      onChange={handleInputChange}
                    />
                  ) : (
                    agent.model
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <strong>Prompt: </strong>
                </Col>
                <Col>
                  {isEditing ? (
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="system_prompt"
                      value={editData.system_prompt}
                      onChange={handleInputChange}
                    />
                  ) : (
                    agent.system_prompt
                  )}
                </Col>
              </Row>


              <Row>
                <Col xs={2}>
                  <strong>Frequency Penalty: </strong>
                </Col>
                <Col>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      name="frequency_penalty"
                      value={editData.frequency_penalty}
                      onChange={handleInputChange}
                    />
                  ) : (
                    agent.frequency_penalty
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <strong>Max Tokens: </strong>
                </Col>
                <Col>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      name="max_tokens"
                      value={editData.max_tokens}
                      onChange={handleInputChange}
                    />
                  ) : (
                    agent.max_tokens
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <strong>Temperature: </strong>
                </Col>
                <Col>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      name="temperature"
                      value={editData.temperature}
                      onChange={handleInputChange}
                    />
                  ) : (
                    agent.temperature
                  )}
                </Col>
              </Row>
            </>
          )}

          {runResult && (
            <Row>
              <Col xs={2}>
                <strong>Run Result: </strong>
              </Col>
              <Col>
                <ReactMarkdown>{runResult}</ReactMarkdown>
              </Col>
            </Row>
          )}

          <div className="mt-4">
            <h3>Run Agent</h3>
            <Form.Group className="mb-3">
              <Form.Label>User Prompt</Form.Label>
              <Form.Control
                type="text"
                value={userPrompt}
                onChange={(e) => setUserPrompt(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="text"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)} // Update websiteUrl state
              />
            </Form.Group>

            <Button onClick={RunAgent}>Run</Button>
            &nbsp;
            <Button className="btn-warning" onClick={toggleEdit}>{isEditing ? "Save" : "Edit"}</Button>
          </div>
        </>
      )}
    </Container>
  );
}

export default ViewAgent;
