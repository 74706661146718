import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import api from "../../../utils/agentApi";
import baseAPi from "../../../utils/api";

function ViewSequence() {
  let { sequenceId } = useParams();

  const [sequence, setSequence] = useState({});
  const [sequenceAgents, setSequenceAgents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isRunLoading, setIsRunLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [runResult, setRunResult] = useState(null);
  const [runData, setRunData] = useState({});
  const [editData, setEditData] = useState({});

  const [selectedAgentId, setSelectedAgentId] = useState('');

  useEffect(() => {
    const fetchDemo = async () => {
      try {
        setIsLoading(true);
        const response = await api.post(`/get_sequence`, { seq_id: sequenceId });
        setSequence(response.data.data);
        setEditData(response.data.data); // Initialize edit data with sequence data

        const seqAgents = await api.get(`/get_sequence_agents`);
        const seqAgentsData = seqAgents.data.data;
        setSequenceAgents(
          seqAgentsData.filter((agent) => agent.sequence_id === sequenceId)
        );

        const agentsResponse = await api.get("/get_agents");
        setAgents(agentsResponse.data.data);

        console.log("Current Axios headers:", api.defaults.headers.common);
        const filesResponse = await baseAPi.get("/file/all_files");
        console.log("files")
        console.log(filesResponse)
        setFiles(filesResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setIsLoading(false);
      }
    };
    fetchDemo();
  }, [sequenceId]);


  // Handle input changes for agent parameters
  const handleChange = (e, agentId, field) => {
    const { value } = e.target;
    setRunData((prevData) => ({
      ...prevData,
      [agentId]: {
        ...prevData[agentId],
        [field]: value,
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleAgentRoleChange = (agentId, role) => {
    setSequenceAgents((prevAgents) =>
      prevAgents.map((agent) =>
        agent.agent_id === agentId ? { ...agent, role } : agent
      )
    );
  };

  const handleAgentChange = async (agentId, sequenceAgentId) => {
    console.log(agentId, sequenceAgentId);
    const selectedAgent = agents.find((agent) => agent.id === agentId);
    const sequenceAgent = sequenceAgents.find(
      (agent) => agent.id === sequenceAgentId
    );

    const updatedAgent = {
      seq_agent_id: sequenceAgent.id, // Existing agent ID
      sequence_id: sequenceId, // Use the current sequence ID
      agent_id: selectedAgent.id, // New agent ID selected
      role: sequenceAgent.role, // Keep the existing role
    };

    try {
      // Call the API to update the agent in the sequence
      await api.post("/new_or_update_sequence_agent", updatedAgent);

      // Update the state with the new agent data
      setSequenceAgents((prevAgents) =>
        prevAgents.map((agent) =>
          agent.id === sequenceAgentId
            ? { ...agent, agent_id: selectedAgent.id }
            : agent
        )
      );
    } catch (error) {
      console.error("Failed to update agent", error);
    }
  };


  const toggleEdit = async () => {
    if (isEditing) {
      try {
        // Prepare sequence data for update, including new agents
        const updatedSequence = {
          seq_id: sequenceId,
          name: editData.name,
          description: editData.description,
          agents: sequenceAgents.map((agent) => ({
            agent_id: agent.agent_id,
            role: agent.role,
            // Only check startsWith if id exists
            id: agent.id && agent.id.startsWith("new_") ? null : agent.id,
            sequence_id: sequenceId, // Ensure the new agents have the correct sequence_id
          })),
        };

        // Send the updated sequence including agents to the backend
        await api.post(`/new_or_update_sequence`, updatedSequence);

        // Update the local state with the saved data
        setSequence(editData);
      } catch (error) {
        console.error("Error updating sequence:", error);
        return; // Exit without toggling if there's an error
      }
    }
    setIsEditing(!isEditing); // Toggle between edit and view mode
  };



  async function RunSequence() {
    setIsRunLoading(true);
    try {
      const sequenceData = {
        seq_id: sequenceId,
        user_id: "20173961-a991-4487-953f-4e47f887ef0d",
        user_prompt_source: [],
        user_prompt_target: [],
        website_url_source: [],
        website_url_target: [],
        file_url_source: [],
        file_url_target: [],
      };

      sequenceAgents.forEach((agent) => {
        const agentData = runData[agent.agent_id] || {};
        if (agent.role === "source") {
          sequenceData.user_prompt_source.push({
            agent_id: agent.agent_id,
            data: agentData.user_prompt,
          });
          sequenceData.website_url_source.push({
            agent_id: agent.agent_id,
            data: agentData.website_url,
          });
          sequenceData.file_url_source.push({
            agent_id: agent.agent_id,
            data: agentData.file_url,
          });
        } else if (agent.role === "target") {
          sequenceData.user_prompt_target.push({
            agent_id: agent.agent_id,
            data: agentData.user_prompt,
          });
          sequenceData.website_url_target.push({
            agent_id: agent.agent_id,
            data: agentData.website_url,
          });
          sequenceData.file_url_target.push({
            agent_id: agent.agent_id,
            data: agentData.file_url,
          });
        }
      });

      console.log(sequenceData)

      const response = await api.post("/run_sequence", sequenceData);
      const result = await response.data.results;
      let resultArray = Object.entries(result).map(([key, value]) => ({
        id: key,
        result: value,
      }));

      setRunResult(resultArray);
      window.scrollTo({ top: 1000, behavior: "smooth" });
      setIsRunLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  // Handle the change in the dropdown to select an agent
  const handleSelectAgent = (e) => {
    setSelectedAgentId(e.target.value); // Update the selected agent ID
  };

  // Add a new agent to the sequence with the selected ID
  const handleAddAgent = async () => {
    if (!selectedAgentId) {
      alert("Please select an agent to add."); // Ensure the user selects an agent
      return;
    }

    // Check if the agent is already in the sequence
    if (sequenceAgents.some(agent => agent.agent_id === selectedAgentId)) {
      alert("This agent is already part of the sequence.");
      return;
    }

    const newAgent = {
      sequence_id: sequenceId, // Ensure the agent is linked to the current sequence
      agent_id: selectedAgentId, // Use the selected agent ID
      role: 'source', // Default role for a new agent
    };

    try {
      // Call the API to add the new agent to the sequence
      const response = await api.post("/new_or_update_sequence_agent", newAgent);
      const savedAgent = response.data.data; // Get the saved agent data

      // Add the new agent to the sequenceAgents state using the response from the backend
      setSequenceAgents((prevAgents) => [...prevAgents, savedAgent]);

      // Reset the dropdown selection after adding the agent
      setSelectedAgentId('');
    } catch (error) {
      console.error("Failed to add agent", error);
    }
  };

  // Remove an agent by index
  const handleRemoveAgent = async (index) => {
    const agentToRemove = sequenceAgents[index]; // Get the agent to remove

    try {
      // Make an API call to remove the agent
      await api.post("/remove_sequence_agent", {
        seq_agent_id: agentToRemove.id, // Pass the ID of the agent to be removed
      });

      // Update the state to remove the agent from the UI
      setSequenceAgents((prevAgents) =>
        prevAgents.filter((_, agentIndex) => agentIndex !== index)
      );
    } catch (error) {
      console.error("Failed to remove agent", error);
    }
  };

  return (
    <Container>
      {isLoading && <p>Loading sequence...</p>}
      {!isLoading && (
        <>
          <h1>{sequence.name}</h1>
          <Row>
            <Col xs={2}>
              <strong>Name: </strong>
            </Col>
            <Col>
              {isEditing ? (
                <Form.Control
                  type="text"
                  name="name"
                  value={editData.name}
                  onChange={handleInputChange}
                />
              ) : (
                sequence.name
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Description: </strong>
            </Col>
            <Col>
              {isEditing ? (
                <Form.Control
                  type="text"
                  name="description"
                  value={editData.description}
                  onChange={handleInputChange}
                />
              ) : (
                sequence.description
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Created: </strong>
            </Col>
            <Col>{sequence.created_at}</Col>
          </Row>

          <div className="mt-4">
            <h3 className="mt-4">Run Agent</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Source Agents</th>
                  <th>Parameters</th>
                </tr>
              </thead>
              <tbody>
                {sequenceAgents.map((agent, index) => (
                  <tr key={agent.id || `new-agent-${index}`}>
                    <td>
                      {isEditing ? (
                        <Form.Control
                          as="select"
                          value={agent.agent_id}
                          onChange={(e) => handleAgentChange(e.target.value, agent.id)}
                        >
                          {agents.map((a) => (
                            <option key={a.id} value={a.id}>
                              {a.name}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        <a href={`/workflows/agents/view/${agent.agent_id}`}>
                          {agents.find((a) => a.id === agent.agent_id)?.name}
                        </a>
                      )}
                    </td>
                    <td>
                      {isEditing ? (
                        <Form.Control
                          as="select"
                          value={agent.role}
                          onChange={(e) => handleAgentRoleChange(agent.id, e.target.value)}
                        >
                          <option value="source">Source</option>
                          <option value="target">Target</option>
                        </Form.Control>
                      ) : (
                        agent.role
                      )}
                    </td>
                    {isEditing && (
                      <td>
                        <Button variant="danger" onClick={() => handleRemoveAgent(index)}>
                          Remove
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>

            {isEditing && (
              <div>
                {/* Dropdown to select an agent to add */}
                <Form.Group>
                  <Form.Label>Select Agent to Add:</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedAgentId}
                    onChange={handleSelectAgent}
                  >
                    <option value="">Select an agent</option>
                    {agents.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Button to add the selected agent */}
                <Button variant="success" onClick={handleAddAgent} className="mt-2">
                  Add Source Agent
                </Button>
              </div>
            )}

            <Button variant="warning" onClick={toggleEdit} style={{ marginBottom: "20px", marginTop: "20px" }}>
              {isEditing ? "Save" : "Edit"}
            </Button>
            &nbsp;

            {
              isEditing && <Button variant="none" onClick={toggleEdit}>
                Cancel
              </Button>
            }


            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Agent Name</th>
                  <th>Role</th>
                  {isEditing && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {sequenceAgents.map((agent, index) => (
                  <tr key={agent.id || `new-agent-${index}`}>
                    <td>
                      {agents.find((a) => a.id === agent.agent_id)?.name} (
                      {agent.role})
                    </td>
                    <td>
                      {/* User Prompt */}
                      <Form.Group className="mb-3">
                        <Form.Label>User Prompt: </Form.Label>
                        <Form.Control
                          type="text"
                          name="user_prompt"
                          value={runData[agent.agent_id]?.user_prompt || ""}
                          onChange={(e) => handleChange(e, agent.agent_id, "user_prompt")}
                        />
                      </Form.Group>

                      {/* Website URL */}
                      <Form.Group className="mb-3">
                        <Form.Label>Website URL: </Form.Label>
                        <Form.Control
                          type="text"
                          name="website_url"
                          value={runData[agent.agent_id]?.website_url || ""}
                          onChange={(e) => handleChange(e, agent.agent_id, "website_url")}
                        />
                      </Form.Group>

                      {/* File URL */}
                      <Form.Group className="mb-3">
                        <Form.Label>File URL: </Form.Label>
                        <Form.Control
                          as="select"
                          name="file_url"
                          value={runData[agent.agent_id]?.file_url || ""}
                          onChange={(e) => handleChange(e, agent.agent_id, "file_url")}
                        >
                          <option value="">Select a file</option>
                          {files.map((file) => (
                            <option key={file.id} value={file.file_url}>
                              {file.original_filename}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>

            </Table>

            <Button
              variant="primary"
              disabled={isRunLoading}
              onClick={!isRunLoading ? RunSequence : null}
              className="my-4"
            >
              {isRunLoading ? "Loading…" : "Run sequence"}
            </Button>
          </div>

          {runResult && (
            <>
              <Row>
                <Col xs={2}>
                  <strong>Run Result: </strong>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Agent ID</th>
                        <th>Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {runResult.map((result) => (
                        <tr key={result.id}>
                          <td>
                            <a href={`${result.id}`}>
                              {agents.find((a) => a.id === result.id)?.name}
                            </a>
                          </td>
                          <td>
                            <ReactMarkdown>
                              {result.result}
                            </ReactMarkdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default ViewSequence;
